import { useMemo } from 'react'

type UrlKey = 'configurationUrl' | 'clientsUrl' | 'tasksUrl' | 'todosUrl' | 'warehousesUrl' | 'areasUrl' | 'circuitsUrl' | 'mapUrl' | 'equipmentsUrl' | 'equipmentTypesUrl' | 'equipmentIssuesUrl' | 'certificationsUrl' | 'staffMembersUrl' | 'schedulesUrl' | 'squadsUrl' | 'shiftsUrl' | 'shiftSchedulesUrl' | 'daysUrl' | 'adminUrl' | 'servicesUrl' | 'usersUrl' | 'teamsUrl' | 'interventionsUrl' | 'printUrl' | 'planningUrl' | 'dashboardUrl' | 'dayDashboardUrl' | 'weekDashboardUrl' | 'monthDashboardUrl' | 'helpUrl' | 'tutorialsUrl' | 'ticketsUrl' | 'linksUrl'

// Define the URLs object with placeholders
const urls: Record<string, string> = {
	// CLIENTS
	clients: '/:serviceId/clients',

	//Help
	help: '/:serviceId/help',
	tutorials: '/:serviceId/help/tutorials',
	tickets: '/:serviceId/help/tickets',
	links: '/:serviceId/help/links',

	// CONFIG
	configuration: '/:serviceId/configuration',
	tasks: '/:serviceId/configuration/tasks',
	warehouses: '/:serviceId/configuration/warehouses',
	areas: '/:serviceId/configuration/areas',
	equipmentTypes: '/:serviceId/configuration/equipmentTypes',
	certifications: '/:serviceId/configuration/certifications',
	shifts: '/:serviceId/configuration/shifts',
	shiftSchedules: '/:serviceId/configuration/shifts/shiftSchedules',
	days: '/:serviceId/configuration/days',

	// CIRCUITS
	circuits: '/:serviceId/circuits',
	map: '/:serviceId/circuits/map',

	// VEHICLES
	equipments: '/:serviceId/equipments',
	equipmentIssues: '/:serviceId/equipments/equipmentIssues',

	// STAFF
	staffMembers: '/:serviceId/staffMembers',
	schedules: '/:serviceId/staffMembers/schedules',
	squads: '/:serviceId/staffMembers/squads',

	// ADMIN
	admin: '/:serviceId/admin',
	users: '/:serviceId/admin/users',
	services: '/services',
	teams: '/:serviceId/admin/teams',

	// PLANNING
	interventions: '/:serviceId/interventions',
	todos: '/:serviceId/interventions/todos',
	planning: '/:serviceId/interventions/planning',
	print: '/:serviceId/interventions/print',

	// DASHBOARD
	dashboard: '/:serviceId/dashboard',
	dayDashboard: '/:serviceId/dashboard/day',
	weekDashboard: '/:serviceId/dashboard/week',
	monthDashboard: '/:serviceId/dashboard/month',
}


export function useUrls(serviceId: string): Record<UrlKey, string> {
	return useMemo(() => {
		const result: Record<UrlKey, string> = {} as Record<UrlKey, string>
		for (const [key, value] of Object.entries(urls)) {
			result[(key + 'Url') as UrlKey] = value.replace(':serviceId', serviceId)
		}
		return result
	}, [serviceId])
}

export function getUrls(serviceId: string): Record<UrlKey, string> {
	const result: Record<UrlKey, string> = {} as Record<UrlKey, string>
	for (const [key, value] of Object.entries(urls)) {
		result[(key + 'Url') as UrlKey] = value.replace(':serviceId', serviceId)
	}
	return result
}

